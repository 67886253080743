import "../../style/pages/showArticles/showArticles.scss";
import researchData from "../../assets/object/researchData";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { categoryIcon, keywords, cite } from "../../assets/image";

function ShowArticles() {
  const { id } = useParams();
  const [research, setResearch] = useState(null);

  useEffect(() => {
    const foundResearch = researchData.find((item) => item.id === parseInt(id));
    setResearch(foundResearch);
  }, [id]);

  if (!research) {
    return <></>;
  }

  return (
    <div className="back">
      <div className="Articles_Box">
        <div className="Top">
          <p>Article Details</p>
        </div>
        <div className="contents">
          <div className="mainText">
            <div className="title">{research.title}</div>
            <div className="authors">{research.authors}</div>
            <p className="middleTitle"> [요약(Abstract)]</p>
            <p>{research.abstract}</p>
            {research.introduction ? (
              <>
                <p className="middleTitle"> [서론(Introduction)]</p>
                <p>{research.introduction}</p>
              </>
            ) : null}
          </div>
          <div className="right_box">
            <div className="right_title">Cite</div>
            <div className="right_container">
              <img src={cite} alt="" />
              <div className="right_cite_description">{research.cite}</div>
            </div>
            <div className="right_title">Keyword</div>

            <ul>
              {research.keywords.map((keyword, index) => (
                <li key={index}>
                  <img src={keywords} alt="" /> {keyword}
                </li>
              ))}
            </ul>

            <div className="right_title">Category</div>
            <ul>
              {research.categories.map((category, index) => (
                <li key={index}>
                  <img src={categoryIcon} alt="" />
                  {category}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="Foot">
          <p>
            © Copyright © 2022 Mathematics-based Industrial Technology
            Innovation Academic Society All right Reserved
          </p>
        </div>
      </div>
    </div>
  );
}

export default ShowArticles;
