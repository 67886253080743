import { BackToTop, Sub_Banner, Sub_Path } from "../../components";
import "../../style/pages/member/membership.scss";
import file from "../../assets/file/miti 입회원서.hwp";

function Membership() {
  return (
    <div className="Membership-Box">
      <Sub_Banner />
      <Sub_Path destination={"회원가입"} />
      <BackToTop />
      <div className="content_box">
        <div className="title_pur">회원가입</div>
        <div className="content">
          <header>
            <p className="center">
              회원가입 <br />
              (1) 회원의 종류 및 자격
            </p>
            <a href={file} download="miti 입회원서.hwp">
              입회원서 다운로드
            </a>
          </header>
          <table className="tg">
            <thead>
              <tr>
                <th>정회원(전공자)</th>
                <td>
                  수학 또는 이와 관계가 있는 지식을 가진 개인으로서 제5조가
                  정하는
                  <br />
                  회비를 납부하는 자. (입회금 : 20,000원, 연회비 : 30,000원)
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>정회원(비전공자)</th>
                <td>
                  다른 도메인 지식을 가지고 있는 개인으로서 제5조가 정하는
                  회비를
                  <br />
                  납부하는 자. (입회금 : 20,000원, 연회비 : 30,000원)
                </td>
              </tr>
              <tr>
                <th>학생회원</th>
                <td>
                  수학 또는 이와 관계 있는 과정을 이수하고 있는 자로서 제5조가
                  <br />
                  정하는 학생회비를 납부하는 자. (입회금 : 10,000원, 연회비 :
                  15,000원)
                </td>
              </tr>
              <tr>
                <th>명예회원</th>
                <td>
                  수학에 공적이 현저하거나 본 학회의 목적달성을 위하여 공헌이
                  <br />
                  지대한 자.
                </td>
              </tr>
              <tr>
                <th>찬조회원</th>
                <td>
                  본 학회의 목적에 찬동하여 그 사업을 찬조하는 개인 또는
                  단체로서
                  <br />
                  찬조회비를 납부한자.
                </td>
              </tr>
            </tbody>
          </table>
          <header>
            <h5>(2) 회원의 권리</h5>
          </header>
          <p className="content_p">
            - 정회원은 총회를 통하여 본회의 운영에 참여하며, 임원의 선거권 및
            피선거권을 갖는다.
            <br />
            - 명예회원, 학생회원 및 찬조회원은 총회에 출석하여 그 의견을 진술할
            수 있다. 단, 표결에는 참가할 수 없다.
            <br />- 본 회가 개최하는 학술적 회합에 참여하고 본 회의 도서류를
            이용할 수 있다.
          </p>
          <header>
            <h5>(3) 탈퇴</h5>
          </header>
          <p className="content_p">
            - 회원으로서 탈퇴하고자 하는 자는 본회에 탈퇴서를 제출하여야 한다.
          </p>
          <header>
            <h5>(4) 회원가입안내</h5>
          </header>
          <p className="content_p">
            - 회원가입을 위해서는 회원가입에 필요한 서류
            <a href={file} download="MITI 입회원서.hwp" className="down">
              (입회원서 다운로드)
            </a>
            를 작성하신 후, 전자우편 (info@miti.kr) 또는 일반우편 (경기도 고양시
            일산서구 킨텍스로 217-59 오피스동 703호 수학기반 산업기술 혁신
            연구학회) 으로 문의 해주시기 바랍니다.
            <br />
          </p>
        </div>
      </div>
    </div>
  );
}

export default Membership;
