import { Sub_Banner, Sub_Path, BackToTop } from "../../components";
import "../../style/pages/symposium/academic_award.scss";

const Academic_Award = () => {
  return (
    <div className="Academic_Award_Box">
      <BackToTop />
      <Sub_Banner />
      <Sub_Path destination={"MITI 연구학회상"} />
      <div className="content_box">
        <div className="title_pur">MITI 연구학회상</div>
        <article className="content">
          <header>
            <p className="header_p">
              MITI 연구학회상 <br />
              제1조 (준거, 목적)
            </p>
          </header>
          <p className="content_p">
            MITI 연구학회 사업의 하나로서 MITI 연구학회상(이하 학회상이라
            칭한다.)을 제정 운영한다.
            <br />
            이 규정은 학회상의 운영에 관한 제반사항을 정하는 것을 목적으로 한다.
            <br />
            <br />
          </p>
          <header>
            <h4>제2조 (학회상의 종류)</h4>
          </header>
          <p className="content_p">
            학회상은 다음 4종으로 한다.
            <br />
            1. 학술상
            <br />
            2. 공로상
            <br />
            3. 교육상
            <br />
            4. 논문상
            <br />
            <br />
          </p>
          <header>
            <h4>제3조 (수상자격)</h4>
          </header>
          <p className="content_p">
            1. 학술상 : 다년간 수학의 연구에 종사하고 우수한 연구 업적을
            이룩하여 학회 발전에 크게 공헌한 회원.
            <br />
            2. 공로상 : MITI 연구학회의 발전을 위하여 공헌이 큰 회원.
            <br />
            3. 교육상 : 다년간 수학 분야에서 교육, 저술 및 강연 활동 등을 통하여
            인재 양성에 크게 공헌한 회원.
            <br />
            4. 논문상 : 최근 5년 이내에 게재된 탁월하게 우수한 논문의 저자로
            참여하여 수학발전에 크게 공헌한 회원.
            <br />
            <br />
          </p>
          <header>
            <h4>제4조 (시상시기)</h4>
          </header>
          <p className="content_p">
            학회상은 정기 총회에서 시상한다.
            <br />
            <br />
          </p>
          <header>
            <h4>제5조 (학회상 수상자의 결정)</h4>
          </header>
          <p className="content_p">
            1.학회상 수상자는 학술위원회에서 회의·심의·의결을 거쳐서 선정한다.
            <br />
            2.학술위원회는 회의록을 작성하여 학회상 수여에 관한 제반사항을
            기록보존한다.
          </p>
        </article>
      </div>
    </div>
  );
};
export default Academic_Award;
