import { BackToTop, Sub_Banner, Sub_Path } from "../../components";
import "../../style/pages/journal/submission_rules.scss";

function Submission_Rules() {
  return (
    <div className="submission_information_Box">
      <Sub_Banner />
      <Sub_Path destination={"투고규정"} />
      <BackToTop />

      <div className="content_box">
        <div className="title_pur">투고규정</div>
        <div className="content">
          <header>
            <p>
              투고규정
              <br /> &#60;논문지&#62;
            </p>
          </header>
          <header>
            <h4>제1 장 총직</h4>
          </header>
          <p className="content_p">
            <br />
            제1 조 논문은 국내외 타 논문지에 투고중이거나 발표되지 않은
            연구결과로서 다음과 같은 조건을 구비하여야 한다.
            <br />
            <br />
            가. 정보보호 및 그 응용분야에 관련되는 내용일 것<br />
            나. 학술 및 산업발전에 기여하는 내용일 것<br />
            다. 독창성이 인정되는 내용일 것<br />
            <br />
            제2 조 국내외 타 논문지에 게재되었거나 심사 중인 원고는 투고할 수
            없으며, 게재논문의 내용에 대한 최종 책임은 저자가 진다.
            <br />
            <br />
            제3 조 논문 투고자 및 공동 저자는 반드시 학회 회원이어야 한다. 단
            초청기고자는 예외로 한다.
            <br />
            <br />
            제4 조 논문의 형태는 논문이 다루는 주제의 범위에 따라 일반 논문과
            짧은 논문으로 구분한다.
            <br />
            짧은 논문은 일반논문에 비해 주제의 범위가 좁고 매수가 4쪽 이내의
            논문을 의미하며, 그 내용은 긴급으로 배포할 구체적이며 명료한 주제에
            관한 것이어야 한다.
            <br />
            <br />
            제5 조 논문지는 연 6회(2월, 4월, 6월, 8월, 10월, 12월)발행하며 해당
            월 말일을 발행일로 한다.
            <br />
            <br />
          </p>
          <header>
            <h4>제2 장 논문의 접수 및 투고</h4>
          </header>
          <p className="content_p">
            <br />
            제6 조 원고채택여부는 본 학회의 논문지 심사규정에 따른 심사과정을
            거쳐 편집위원회에서 결정하되 편집위원회는
            <br />
            원고의 부분적 수정 또는 보완을 요구할 수 있다. <br />
            <br />
            제7 조 원고는 수시로 온라인논문심사시스템에서 접수하며 원고의
            투고일은 온라인 접수가 확인된 날로 한다.
            <br />
            게재 확정일은 접수된 논문의 심사결과, 게재가 승인을 받은 날로 한다.{" "}
            <br />
            <br />
            제8 조 투고 시, 온라인논문심사시스템에 전체 저자 명단을 입력해야
            한다. 투고 후 저자 명단은 원칙적으로 수정할 수 없으며,
            <br />
            부득이한 경우 저자의 변경에는 편집위원회 의결이 필요하다.
            <br />
            <br />
            제9 조 저자가 2인 이상인 경우 처음 기재된 저자를 제 1저자로
            간주하며, 타 공동저자를 대표하여 제출된 논문에 대한 책임과 권리를
            갖는다.
            <br />
            공동저자들의 합의에 따라서 교신(책임)저자를 지정·명기할 수 있다.
            <br />
            <br />
            제10 조 투고자는 논문 1편당 소정의 심사료(편당 50,000원)를 논문
            제출시 납부해야 한다.
            <br />
            단, 긴급심사를 요청하는 경우 심사료는 300,000원으로 한다. 짧은
            논문의 심사료는 50,000원이다.
          </p>
          <table className="tg">
            <thead>
              <tr>
                <th className="tg-c3ow">구분</th>
                <th className="tg-c3ow">일반논문</th>
                <th className="tg-baqh">짧은논문</th>
                <th className="tg-0lax">긴급논문</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="tg-c3ow">심사료</td>
                <td className="tg-c3ow">5만원</td>
                <td className="tg-baqh">5만원</td>
                <td className="tg-0lax">30만원</td>
              </tr>
            </tbody>
          </table>
          <header>
            <h4>제3 장 원고관련 사항</h4>
          </header>
          <br />
          <p className="content_p">
            제11 조 제출 원고는 투고용(심사용) 및 게재용 원고의 형식이 모두
            동일하다.
            <br />
            한글 워드프로세서를 사용하여 A4용지 기준으로 그림과 표를 포함하여
            20매 이내로 한다. 단, 짧은 논문은 4매 이하로 제한한다.
            <br />
            <br />
            제12 조 투고용(심사용) 원고에는 저자의 인적사항을 기재하지 않는다.
            <br />
            <br />
            제13 조 제출 원고는 줄 간격 150%, 2단(2-column)으로 작성하여 한글
            워드프로세서 파일로 제출한다.
            <br />
            게재용 원고에는 모든 저자의 인적사항과 반명함판 크기의 사진, 그리고
            간단한 약력(연구분야 포함)을 포함시킨다.
            <br />
            단, 짧은 논문일 경우에는 인적사항을 생략한다.
            <br />
            저자가 두 사람 이상일 경우 주저자에는 †기호를, 교신저자에는 ‡기호를
            성명 뒤에 위첨자로 표시한다.
            <br />
            소속 기관이 두 개 이상일 경우에는 1, 2, 3, ...등을 필요한 만큼
            사용하여 성명 뒤와 소속기관 앞에 위첨자로 표시한다.
            <br />
            소속기관에서의 직위 또한 포함하여야 한다.
            <br />
            <br />
            (예) 홍길동,† 이순신‡
            <br />
            (예) 홍길동,1† 이순신2‡
            <br />
            1한국대학교(대학원생), 2한국연구소(연구원)
            <br />
            <br />
            제14 조 논문은 원칙적으로 한글로 작성하되, 논문제목, 저자 성명, 저자
            소속기관 및 직위, 요약은 영문을 병기하여야 한다.
            <br />
            국문요약은 700자 이내, 영문요약은 250단어 이내로 한다.
            <br />
            <br />
            제15 조 영문은 문장의 첫 자만 대문자로 표기하고 나머지는 소문자로
            표기한다. (단, 고유명사의 경우는 예외)
            <br />
            <br />
            제16 조 영문성명은 이름을 먼저 쓰고 성은 뒤로 쓴다.
            <br />
            <br />
            제17 조 논문은 국문요약, 영문요약, 본문, 부록, 참고문헌의 순으로
            하고, 영문 요약하단에 5개 이하의 keyword를 기재한다.
            <br />
            영문논문의 경우는 국문으로 작성되는 부분을 생략한다.
            <br />
            <br />
            제18 조 장에 해당되는 전호는 로마자 (Ⅰ,Ⅱ,Ⅲ,Ⅳ,)로 절에 해당되는
            번호는 아라비아숫자(1, 2, 3, 4,)로 표기한다.
            <br />
            <br />
            제19 조 그림, 표는 반드시 영문으로 작성하며, 본문과 함께 페이지를
            정하고 그림 또는 표가 들어갈 공간을 남겨 놓고
            <br />
            설명을 쓰되 그림은 공간 하단에 표는 상단에 영문으로 작성한다.
            <br />
            <br />
            제20 조 참고문헌은 반드시 영문으로 작성하며, 본문에 인용된 순서대로
            기재하되 기재방법은 원칙적으로 다음과 같이 표기한다.
            <br />
            <br />
            가. 학술지: 저자명, 제목, 학술지명, 권, 호, 면수, 월, 연도
            <br />
            나. 단행본: 저자명, 도서명, 출판사명, 주소지, 면수, 연도
            <br />
            <br />
            [1] Gil-dong Hong and Sun-sin Lee, “The subject of an essay,”
            journal of The Korea Institute of information
            <br />
            Security & Cryptology, 10(2), pp. 100-103, Feb. 2012.
            <br />
            [2] W. Diffie and M.E. Hellman, New directions in cryptography,
            <br />
            IEEE Transactions on Information Theory, vol. IT-22, no. 6, pp.
            644-654, Nov. 1976.
            <br />
            <br />
            제21 조 본문에서 인용한 참고문헌 번호는 인용문 우측 상단에 [ ]안에
            기입한다.
            <br />
            <br />
          </p>
          <header>
            <h4>제4 장 논문심사 및 출판</h4>
          </header>
          <p className="content_p">
            <br />
            제22 조 투고된 논문에 대한 심사는 본 학회의 논문지 심사규정에 따라
            3인 이상의 전문 심사위원에 의해 진행된다. <br />
            <br />
            제23 조 재심이 요구되는 논문의 경우, 재심 판정을 전달받은 이후로
            일반 논문 및 짧은 논문의 경우는 1개월 이내,
            <br />
            긴급 논문의 경우는 2주 이내에 수정본을 제출하여야 하며, 기타 연락 및
            통보 없이 해당 기간이 지나면 포기하는 것으로 간주한다. <br />
            <br />
            제24 조 긴급심사를 요청한 경우, 1심에 대한 결과는 1개월 이내에
            확정된다.
            <br />
            짧은 논문은 1차 심사만으로 게재여부를 확정한다. (짧은 논문은 수정후
            재심이 없다.) <br />
            <br />
            제25 조 논문은 심사에 통과된 것을 접수순서대로 게재함을 원칙으로
            한다. 단, 특별한 경우 편집위원회가 우선순위를 변경할 수 있다.
            <br />
            <br />
            제26 조 논문 게재료는 아래표와 같이 적용한다. 단, 긴급논문의 경우,
            1차 심사완료기간이 1개월을 초과하면 일반논문의 게재료와 동일하게
            적용한다.
          </p>
          <table className="tg">
            <thead>
              <tr>
                <th className="tg-c3ow">구분</th>
                <th className="tg-c3ow">일반논문</th>
                <th className="tg-baqh">짧은논문</th>
                <th className="tg-0lax">긴급논문</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="tg-c3ow">기본 6면(페이지)(짧은 논문: 4면)</td>
                <td className="tg-c3ow">15만원</td>
                <td className="tg-baqh">15만원</td>
                <td className="tg-0lax">30만원</td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td className="tg-c3ow">7면(페이지) 이상</td>
                <td className="tg-c3ow">3만원/면(페이지)</td>
                <td className="tg-baqh">N/A</td>
                <td className="tg-0lax">6만원/면(페이지)</td>
              </tr>
            </tbody>
          </table>
          <p className="content_p">
            논문에 지원기관을 명시할 경우 추가로 100,000원을 납부하여야 한다.
            <br />
            논문 게재료는 최종심사 결과 이후 즉시 납부해야 하며, 납부가 확인되기
            전에는 출판사로 원고가 이송되지 않는다. <br />
            <br />
            <br />
            제27 조 논문지에 게재된 논문은 별쇄본 20부를 투고자에게 증정한다.
            <br />
            20부 이상의 별쇄본을 원하는 경우는 게재 통보 시 필요부수를 결정하고
            초고 완료시까지 실비를 납부하여야 한다.
            <br />
            <br />
            제28 조 본 학회의 논문지에 게재된 논문에 대해 저자는 저작권
            이양동의서 및 확약서를 제출하여야 한다.
            <br />
            저작권은 전적으로 학회가 가지며, 논문지에 게재된 논문은 본 학회의
            승인 없이 무단 복제할 수 없다. <br />
            <br />
            제29 조 저자는 본 학회의 논문지에 게시된 저자점검표를 통하여 작성된
            논문의 투고규정 준수 여부를 확인하여야 하고,
            <br />
            투고 규정에 맞지 않을 시 본 학회는 해당 논문을 반려할 수 있다.
            <br />
            <br />
          </p>
          <header>
            <h4>제5 장 연구출판윤리</h4>
          </header>
          <p className="content_p">
            <br />
            제30 조 국내외 타 논문지에 게재되었던 원고는 투고할 수 없고,
            게재논문의 내용에 대한 최종 책임은 저자(들)이 진다.
            <br />
            별도 제정된 수학기반 산업기술 혁신 연구학회 논문 윤리규정을
            준수하며,
            <br />
            국제표준출판윤리(http://publicationethics.org/international-standards-editors-and-authors)를
            따른다. <br />
            <br />
            제31 조 투고된 논문은 표절검사 프로그램을 활용하여 표절 검사를
            진행할 수 있다. <br />
            <br />
            제32 조 연구 부정행위 논문이 발생한 경우 본 학회의 연구윤리규정과
            국제표준출판윤리
            <br />
            COPE Flow Chart를 따라 사후조치를 진행한다.
          </p>
          <br />
          <br />
          <br />
          <header>
            <h2>&#60;논문지 윤리규정&#62;</h2>
            <p> 제1절 총칙</p>
          </header>
          <br />
          <header>
            <h4>제1 조(연구윤리규정의 목적)</h4>
          </header>
          <p className="content_p">
            <br />
            본 규정은 수학기반 산업기술 혁신 연구학회의 연구윤리 및 진실성을
            확보하는데 필요한 역할과
            <br />
            책임에 관하여 기본적인 원칙과 방향을 제시함을 목적으로 한다.
            <br />
            <br />
          </p>
          <header>
            <h4>제2 조(적용대상 및 범위)</h4>
          </header>
          <p className="content_p">
            <br />
            본 규정은 수학기반 산업기술 혁신 연구학회 논문지에 논문을 투고 및
            발표하는 모든 회원과
            <br />
            본 학회에서 발간되는 저작물, 모든 연구사업 및 학술 활동 관련자에게
            적용된다. <br />
            <br />
          </p>
          <header>
            <h4>제3 조(연구부정행위의 범위)</h4>
          </header>
          <p className="content_p">
            <br />
            (1) 본 규정에서 제시하는 연구부정행위(이하 ”부정행위“라 한다)는
            논문의 발표 및 게재와
            <br />
            연구의 제안, 연구의 수행, 연구결과의 보고 및 발표 등에서 행하여진
            위조·변조·표절, 부당한 논문저자 표시,
            <br />
            중복게재, 공적 허위진술 등을 말하며 다음 각 호와 같다.
            <br />
            <br />
            1. “위조”는 존재하지 않는 데이터 또는 연구결과 등을 허위로 만들어
            내는 행위를 말한다.
            <br />
            <br />
            2. “변조”는 연구 재료·장비·과정 등을 인위적으로 조작하거나
            <br />
            데이터를 임의로 변형·삭제함으로써 연구 내용 또는 결과를 왜곡하는
            행위를 말한다.
            <br />
            <br />
            3. “표절”이라 함은 타인의 아이디어, 연구내용·결과 등을 정당한 승인
            또는 인용 없이 도용하는 행위를 말한다.
            <br />
            <br />
            4. “부당한 논문저자 표시”는 연구내용 또는 결과에 대하여
            과학적·기술적 공헌 또는 기여를 한 사람에게
            <br />
            정당한 이유 없이 논문저자 자격을 부여하지 않거나, 과학적·기술적 공헌
            또는 기여를 하지 않은 자에게
            <br />
            감사의 표시 또는 예우 등을 이유로 논문저자 자격을 부여하는 행위를
            말한다.
            <br />
            <br />
            5. “중복게재”는 동일한 내용의 논문을 두 개 이상의 학술지에 게재한
            행위를 말한다.
            <br />
            <br />
            6. “공적 허위진술”는 본인의 학력, 경력, 자격, 연구업적 및 결과 등에
            관해서 허위 진술하는 행위를 말한다.
            <br />
            <br />
            7. 본인 또는 타인의 부정행위 혐의에 대한 조사를 고의로 방해하거나
            제보자에게 위해를 가하는 행위
            <br />
            <br />
            8. 과학기술계에서 통상적으로 용인되는 범위를 심각하게 벗어난 행위 등
          </p>

          <header>
            <p>제2 절 연구관련 윤리규정</p>
          </header>
          <br />
          <header>
            <h4>제4 조(기저)</h4>
          </header>
          <p className="content_p">
            <br />
            본 학회에서 논문을 발표 또는 투고하는 모든 저자는 본 학회 정관에
            규정된 대로
            <br />
            학문의 발전을 위해 학자의 양심에 기인하여 논문을 투고하고 연구사업을
            수행하는 연구자는
            <br />
            연구를 성실히 수행하는 과정에서 본 윤리규정을 준수하여야 한다.
            <br />
            <br />
          </p>
          <header>
            <h4>제5 조(저자가 지켜야 할 윤리규정)</h4>
          </header>
          <p className="content_p">
            <br />
            학회 회원은 본 규정에서 제시하는 연구부정행위에 대해서 책임을
            져야한다.
            <br />
            또한 부정행위는 제3조에서와 같이 논문의 게재및발표와 연구의 제안,
            연구의 수행, 연구결과의 보고 및 발표 등에서
            <br />
            행하여진 위조·변조·표절, 부당한 논문저자 표시, 중복게재, 공적
            허위진술 등을 말한다.
          </p>
          <br />
          <header>
            <p>제3 절 연구윤리규정 시행지침</p>
          </header>
          <br />
          <header>
            <h4>제6 조(연구윤리규정 서약)</h4>
          </header>
          <p className="content_p">
            <br />
            수학기반 산업기술 혁신 연구학회는 회원의 연구 및 사업 수행시
            준수해야할 연구윤리규정을 홍보하며,
            <br />
            본 학회 회원은 윤리규정을 준수할 것을 논문 투고시 윤리규정서약서란에
            반드시 서약하여야 한다.
            <br />
            <br />
          </p>
          <header>
            <h4>제7 조(윤리규정준수확약서)</h4>
          </header>
          <p className="content_p">
            <br />
            저자는 게재가 확정된 논문이 발간되기 전에 연구윤리준수확약서를 학회
            회장에게 반드시 제출하여야 한다.
            <br />
            <br />
          </p>
          <header>
            <h4>제8 조(연구윤리위원장)</h4>
          </header>
          <p className="content_p">
            <br />
            연구윤리위원장은 발간될 각 논문에 대한 자체 연구윤리준수 여부를
            검증한 결과를 학회 회장에게 제출하여야 한다.
            <br />
            <br />
          </p>
          <header>
            <h4>제9 조(연구윤리위원회 구성 및 운영)</h4>
          </header>
          <p className="content_p">
            <br />
            ① 연구윤리위원회는 위원장 1인과 5인 이상의 위원으로 구성함을
            원칙으로 하며, 위원은 본 학회 회원으로서 회장이 임명한다.
            <br />
            <br />
            ② 윤리위원장은 위원회를 대표하며, 회의를 소집하고 주재한다.
            <br />
            <br />
            ③ 윤리위원회의 사무를 처리하기 위하여 윤리위원회에 간사 1인을 둘 수
            있다.
            <br />
            <br />
            ④ 윤리위원장은 위원회의 회의를 소집하고 그 의장이 되며, 회의는
            재적위원 과반수 이상의 출석과
            <br />
            출석위원 2분의 1을 초과하는 찬성으로 의결한다. 단, 위원장은
            심의안건이 경미하다고 인정할 때에는 서면심의에 부의할 수 있다.
            <br />
            <br />
            ⑤ 본 조사 착수 이전에 제보자에게 제1항의 규정에 의한 연구윤리위원
            명단을 알리고,
            <br />
            제보자가 연구윤리위원 기피에 관한 정당한 이의를 제기할 경우 이를
            수용할 수 있다.
            <br />
            <br />
          </p>
          <header>
            <h4>제10 조(연구윤리위원회 권한)</h4>
          </header>
          <p className="content_p">
            <br />
            ① 연구윤리위원회는 연구윤리규정 위반으로 보고된 사안에 대하여
            제보자, 피조사자, 증인, 참고인에 대하여
            <br />
            진술을 위한 출석을 요구할 수 있으며, 피조사자에게 자료의 제출을
            요구할 수 있다. 이 경우 피조사자는 반드시 응하여야 한다.
            <br />
            <br />
            ② 연구윤리위원회는 소속기관의 장에게 사실로 판정된 부정행위에 대하여
            이를 통보할 수 있다.
            <br />
            <br />
          </p>
          <header>
            <h4>제11 조(학회의 권한과 역할</h4>
          </header>
          <p className="content_p">
            <br />
            수학기반 산업기술 혁신 연구학회는 연구및 논문에 대한 부정행위 신고
            접수창구를 마련하고,
            <br />
            학회에서 진실성 검증이 공정하고 합리적으로 이루어졌는지에 대해
            심사하며, 이를 토대로 후속조치를 실시한다.
          </p>
          <header>
            <p>제4 절 연구진실성 검증 절차와 기준</p>
          </header>
          <br />
          <header>
            <h4>제12 조(진실성 검증 책임주체)</h4>
          </header>
          <p className="content_p">
            <br />
            부정행위의 발생을 인지하거나 제보가 있을 경우 수학기반 산업기술 혁신
            연구학회는 이에 대한 검증 활동을 수행할 수 있으며,
            <br />
            회장은 연구윤리위원회를 통하여 성실하게 처리하여야 한다.
            <br />
            단, 피조사자가 조사위원회에서 요구하는 자료를 고의로 훼손하였거나
            제출을 거부하는 경우에 요구 자료에 포함되어 있다고
            <br />
            인정되는 내용의 진실성을 입증할 책임은 피조사자에게 있다.
            <br />
            <br />
          </p>
          <header>
            <h4>제13 조(이의제기 및 변론의 권리보장)</h4>
          </header>
          <p className="content_p">
            <br />
            윤리위원회는 위원회 간사와 피조사자에게 의견진술, 이의제기 및 변론의
            권리와 기회를 동등하게 보장하여야 하며
            <br />
            관련 절차를 사전에 알려주어야 한다.
            <br />
            <br />
          </p>
          <header>
            <h4>제14 조(본조사)</h4>
          </header>
          <p className="content_p">
            <br />
            ① 본조사는 부정행위의 혐의에 대한 사실 여부를 입증하기 위한 절차를
            말하며,
            <br />
            제10조의 규정에 따라 연구윤리위원회 등을 구성하여 진행하여야 한다.
            <br />
            <br />
            ② 연구윤리위원회는 제보자와 피조사자에게 의견진술의 기회를 주어야
            하며,
            <br />
            본조사결과에 대해서도 이의제기 및 변론의 기회를 주어야 한다.
            <br />
            당사자가 이에 응하지 않을 경우에는 이의가 없는 것으로 간주한다.
            <br />
            <br />
            ③ 본조사결과에 대한 제보자와 피조사자의 이의제기 또는 변론 내용과
            <br />
            그에 대한 처리결과는 조사결과 보고서에 포함되어야 한다.
            <br />
            <br />
          </p>
          <header>
            <h4>제15 조(조사결과의 보고)</h4>
          </header>
          <p className="content_p">
            <br />
            (1) 연구윤리위원회는 본조사의 결과와 내용을 10일 이내에 본 학회
            회장에게 보고하여야 한다.
            <br />
            <br />
            (2) 본조사의 결과보고서에는 다음 각 호의 사항이 반드시 포함되어야
            한다.
            <br />
            <br />
            1. 제보의 내용
            <br />
            <br />
            2. 조사의 대상이 된 부정행위
            <br />
            <br />
            3. 연구윤리위원회의 조사위원 명단
            <br />
            <br />
            4. 관련 증거 및 증인
            <br />
            <br />
            5. 해당 연구에서의 피조사자의 역할과 혐의의 사실 여부
            <br />
            <br />
            6. 징계조치및수위 권고
            <br />
            <br />
            7. 조사결과에 대한 제보자와 피조사자의 이의제기 또는 변론 내용과
            그에 대한 처리결과
            <br />
            <br />
          </p>
          <header>
            <h4>제16 조(논문게재 등 징계조치및수위)</h4>
          </header>
          <p className="content_p">
            <br />
            (1) 학회장은 윤리위원회의 징계건의가 있을 경우 다음 각항을 참고하여
            징계조치 수위를 정하여 징계조치를 취한다.
            <br />
            다음 각 항보다 더욱 적절한 징계조치가 있다고 판단되면 그에 따를 수도
            있다.
            <br />
            <br />
            1. (주의) 해당 논문의 모든 저자에게 부당행위에 관한 사실을 알리고,
            향후 논문의 작성 시에 주의하도록 서면 조치한다.
            <br />
            <br />
            2. (경고) 해당 논문의 모든 저자에게 부당행위에 적용된 사례를 알리고,
            기게재된 논문을 철회하며,
            <br />
            향후 논문의 작성시에 주의하도록 서면으로 경고한다.
            <br />
            <br />
            3. (문책) 해당 논문의 모든 저자에게 부당행위에 관한 사실을 알리고
            기게재된 논문을 철회한다.
            <br />
            또한 현재 본 학회에서 부정행위 저자들의 심사 중인 모든 논문 및
            게재를 위해 대기 중인 모든 논문을 취소한다.
            <br />
            그리고 해당 논문의 주저자는 3년 동안 본 학회에 논문을 제출할 수
            없다.
            <br />
            논문 표절의 경우, 위의 조치 후에 학회장은 원 논문의 저자들에게
            사과의 공문을 발송한다.
            <br />
            <br />
          </p>
          <header>
            <h4>제17 조(연구수행 등에 대한 징계조치)</h4>
          </header>
          <p className="content_p">
            <br />
            (1) 회장은 제15조 제1항의 규정에 의하여 보고를 받은 경우
            조사내용·결과 및 그에 따른 판정의 합리성과 타당성에 문제가 있다고
            <br />
            판단되는 경우 연구윤리위원회에 추가적인 조사의 실시 또는 조사와
            관련된 자료의 제출을 요구할 수 있다.
            <br />
            <br />
            (2) 수학기반 산업기술 혁신 연구학회는 판정결과에 근거하여 다음
            각호의 후속 조치를 취할 수 있고, 이를 피조사자에게 통보할 수 있다.
            <br />
            <br />
            1. 연구부정행위 관련자 소속기관으로의 징계의 요구
            <br />
            <br />
            2. 3년 이하의 학회 회원자격 정지
            <br />
            <br />
            3. 학회지 및 인터넷 매체를 통한 공지
            <br />
            <br />
            4. 그 밖의 부정행위의 규모·범위 등을 고려한 별도의 조치 사항
            <br />
            <br />
            (3) 제2항 2호의 회원자격 정지라 함은 다음 각 호의 사항에 대한 제한을
            말한다.
            <br />
            <br />
            1. 학회지 논문 게재
            <br />
            <br />
            2. 학회 활동 참여
            <br />
            <br />
            3. 논문의 직권 및 인용
            <br />
            <br />
            4. 기타 회원으로서 가지는 권리
            <br />
            <br />
          </p>
          <header>
            <h4>제18 조(기록의 보관 및 공개)</h4>
          </header>
          <p className="content_p">
            <br />
            조사와 관련된 기록 및 징계조치에 관한 기록은 본 학회 사무국에서
            보관하여야 한다.
            <br />
            최종보고서는 판정이 끝난 이후에 공개할 수 있으나,
            제보자·조사위원·증인·참고인·자문에 참여한 자의 명단 등 신원과
            <br />
            관련된 정보에 대해서는 당사자에게 불이익을 줄 가능성이 있을 경우
            공개대상에서 제외할 수 있다.
            <br />
            <br />
          </p>
          <header>
            <h4>제19 조(기타)</h4>
          </header>
          <p className="content_p">
            <br />
            본 규정에서 규정하지 않은 사항은 수학기반 산업기술 혁신 연구학회의
            관련 내규에 따르고,
            <br />그 이외의 사항은 수학기반 산업기술 혁신 연구학회 이사회에서
            논의하여 결정한다.
          </p>
          <br />
          <br />
          <br />
          <header>
            <h2>학회지</h2>
          </header>
          <p className="content_p">
            제1 조 투고자는 회원에 한함을 원칙으로 한다.
            <br />
            단, 회원과의 공동기고자 및 초청기고자는 예외로 한다.
            <br />
            <br />
            제2 조 학회지에 게재할 원고의 종류는 기획기고, 일반기고(기술해설,
            기술소개, 기술보고), 보고 및 기타 편집위원회가 인정하는 것으로 한다.
            <br />
            <br />
            제3 조 기획기고의 특집호 주제와 이의 주관 편집위원은 편집위원회에서
            정한다. 기획기고의 특집호에 수록할 원고는
            <br />
            특집호를 주관하는 편집위원이 위촉한다.
            <br />
            <br />
            제4 조 보고는 전문위원회의 조사보고, 학회활동의 상황보고, 시찰 또는
            출장보고, 제작 또는 실험보고등으로 한다.
            <br />
            <br />
            제5 조 원고채택 여부는 편집위원회에서 결정한다. 편집위원회 추천에
            따라 국내외에서 기 발표되었던 원고도 투고할 수 있다. <br />
            <br />
            제6 조 원고는 수시로 접수하며 원고의 접수일은 그 원고가 학회에
            도착된 일자로 한다.
            <br />
            <br />
            제7 조 원고는 국문으로 작성하는 것을 원칙으로 하되 필요한 경우
            영문용어를 사용할 수 있다.
            <br />
            영문용어의 경우 문장의 첫자인 경우만 대문자를 쓰고 그 외에는
            소문자를 쓴다.
            <br />
            (단, 고유명사의 경우는 예외) 하지만 편집위원회의 허가를 득한
            영문원고의 경우는 전적으로 영어만 사용할 수 있다.
            <br />
            <br />
            제8 조 원고는 가능한 워드프로세서를 사용하여 A4용지에 한줄 건너서
            (double space)로 인쇄하고
            <br />
            그림, 표를 포함하여 15매 이내의 내용을 기본으로 한다. 원고의 내용은
            전자메일로 학회에 송부하거나
            <br />
            기타 저장장치에 수록하여 제출하며, 이 경우 “아래하한글”을 사용하여
            작성하여야 하며 이의 세부 양식은
            <br />
            학회 홈페이지(http://kiisc.or.kr/)에서 내려받기하여 사용한다.
            <br />
            <br />
            제9 조 원고표지에 제목, 저자명, 소속기관, 직위, 이메일 등을 필히
            기입한다.
            <br />
            저자 직위 표시 예) * 한국대학교 컴퓨터공학과 논문지향시스템 연구소
            (대학원생, hong@paper.korean.ac.kr),
            <br />
            ** 한국연구소 (연구원, kim@hankook.re.kr)
            <br />
            <br />
            제10 조 원고는 본문, 부록, 참고문헌의 순으로 한다.
            <br />
            <br />
            제11 조 본문중 장에 해당되는 번호는 로마자 (I, II, III, VI, ...)로,
            절에 해당되는 번호는 아라비아자(1, 2, 3, 4, ...)로 표기한다.
            <br />
            <br />
            제12 조 참고문헌은 반드시 본문에 인용한 것만을 인용순서대로 기재하되
            기재방법은 원칙적으로 다음과 같이 표기한다.
            <br />
            가. 논문지 : 저자명, 제목, 잡지명, 권, 호, 면수, 월, 연도
            <br />
            나. 단행본 : 저자명, 도서명, 출판사명, 소재지, 면수, 연도
            <br />
            <br />
            제13 조 본문에서 인용한 참고문헌 번호는 인용문 우측 상단의 [ ]안에
            기입한다.
            <br />
            <br />
            제14 조 원고의 채택이 결정되면 저자는 반명함판 사진 파일(jpg, tif)과
            간단한 약력(연구분야 포함)을 200자 이내로 작성하여 학회로 제출하도록
            한다.
            <br />
            <br />
            제15 조 학회지에 게재된 기고는 본 학회의 승인없이 무단 복제할 수
            없다.
            <br />
            <br />
            제16 조 투고된 기고는 컴퓨터상에서 데이타베이스화하여 이를 불특정
            다수에게 공개함에 동의하는 것으로 간주한다.
            <br />
            <br />
            3. 학술대회
            <br />
            [정기학술대회]
            <br />
            게시판 개설
            <br />
            [MITI 연구학회상]
            <br />
            제1조 (준거, 목적)
            <br />
            <br />
            MITI 연구학회 사업의 하나로서 MITI 연구학회상(이하 학회상이라
            칭한다.)을 제정 운영한다.
            <br />
            이 규정은 학회상의 운영에 관한 제반사항을 정하는 것을 목적으로 한다.
            <br />
            <br />
            제2조 (학회상의 종류)
            <br />
            <br />
            학회상은 다음 4종으로 한다.
            <br />
            1. 학술상
            <br />
            2. 공로상
            <br />
            3. 교육상
            <br />
            4. 논문상
            <br />
            <br />
            제3조 (수상자격)
            <br />
            <br />
            1. 학술상 : 다년간 수학의 연구에 종사하고 우수한 연구 업적을
            이룩하여 학회 발전에 크게 공헌한 회원.
            <br />
            2. 공로상 : MITI 연구학회의 발전을 위하여 공헌이 큰 회원.
            <br />
            3. 교육상 : 다년간 수학 분야에서 교육, 저술 및 강연 활동 등을 통하여
            인재 양성에 크게 공헌한 회원.
            <br />
            4. 논문상 : 최근 5년 이내에 게재된 탁월하게 우수한 논문의 저자로
            참여하여 수학발전에 크게 공헌한 회원.
            <br />
            <br />
            제4조 (시상시기)
            <br />
            <br />
            학회상은 정기 총회에서 시상한다.
            <br />
            <br />
            제5조 (학회상 수상자의 결정)
            <br />
            <br />
            1.학회상 수상자는 학술위원회에서 회의·심의·의결을 거쳐서 선정한다.
            <br />
            2.학술위원회는 회의록을 작성하여 학회상 수여에 관한 제반사항을
            기록보존한다.
          </p>
        </div>
      </div>
    </div>
  );
}
export default Submission_Rules;
