import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";

import "../../style/pages/introduce/articles.scss";
import file from "../../assets/file/수학기반 산업기술 혁신 연구학회 정관.hwp";
import { BackToTop, Sub_Banner, Sub_Path } from "../../components";

function Articles() {
  return (
    <div className="Articles">
      <BackToTop />
      <Sub_Banner />
      <Sub_Path destination={"정관"} />

      <div className="Title_Box">
        <div className="Title_Pur">정관</div>
        <div className="Articles_Box">
          <p>
            <span>제1장 총칙</span>
            <br />
            제1조 (명칭) 이 단체의 명칭은 ‘수학기반 산업기술 혁신 연구학회’라
            한다.
            <br />
            <br />
            제2조 (목적) 이 단체는 수학기반의 전문성을 바탕으로 국적을 불문하고
            암호, 인공지능 등<br />
            산업기술 관련 분야에 대한 논문 출판 및 학술세미나를 제공하고자 하며,
            <br />
            특히 그 대상으로 각 분야에서의 전문가 뿐만 아니라 비전문가를
            대상으로 산업기술 분야에
            <br />
            대한 연구의 장을 만들고, 이를 통하여 학문적 연구의 질 향상에
            기여함을 목적으로 한다.
            <br />
            <br />
            제3조 (사업) 이 단체는 제2조의 목적을 달성하기 위하여 다음 각 호의
            사업을 수행한다.
            <br />
            1. 암호, 인공지능 등 산업기술 분야 논문 심사 및 출판
            <br />
            2. 암호, 인공지능 등 산업기술 관련 학술 세미나 및 컨퍼런스 주최
            <br />
            3. 기타 이 단체의 목적을 수행하기 위한 사업
            <br />
            <br />
            <br />
            제4조 (사무소의 소재지) 이 단체의 주사무소는 경기도 고양시에 두며
            필요에 따라 국내, 국외에 지부를 둘 수 있다.
            <br />
            <br />
            <br />
            <br />
            <br />
            <span>제2장 회원 및 임원</span>
            <br />
            제5조 (회원의 자격) 이 단체의 회원은 설립취지에 동의하고 소정의
            가입신청서를 제출하여
            <br />
            운영위원회의 승인을 얻은 자를 정회원으로 하며 정회원만이 총회의
            의결권을 갖는다.
            <br />
            <br />
            제6조 (회원의 권리와 의무)
            <br />
            ① 정회원은 총회를 통하여 이 단체의 운영에 참여할 권리를 가진다.
            <br />
            ② 정회원은 본회의 정관, 규정 및 각종 회의의 의결사항을 준수하고 회비
            및 제 부담금을 납부할 의무를 진다.
            <br />
            <br />
            제7조 (정회원의 탈퇴 및 제명)
            <br />
            ① 회원은 본인의 의사에 따라 자유롭게 탈퇴서를 작성 운영위원회에 제출
            후 탈퇴할 수 있다.
            <br />
            ② 회원이 다음 각호의 사유에 해당될 경우에는 운영위원회의 의결을 거쳐
            제명할 수 있다.
            <br />
            1. 본회의 명예를 손상시키고 목적수행에 지장을 초래한 경우
            <br />
            2. 1년 이상 회원의 의무를 준수하지 않는 자<br />
            <br />
            제8조 (임원의 구성) 이 단체는 다음의 임원을 둔다.
            <br />
            ① 운영위원장 1인, 운영위원장을 포함한 운영위원 2인 이상 3인 이하,
            감사 1인을 둔다.
            <br />
            <br />
            제9조 (임원의 선임)
            <br />
            ① 운영위원장, 운영위원, 감사는 제17조의 방법에 의하여 총회에서
            선출한다.
            <br />
            ② 임원의 보선은 결원이 발생한 날로부터 6개월 이내로 하여야 한다.
            <br />
            <br />
            제10조 (임원의 해임) 임원이 다음 각호에 해당하는 행위를 한 때에는
            총회의 의결로 해임할 수 있다.
            <br />
            1. 이 단체의 목적에 위배되는 행위
            <br />
            2. 임원간의 분쟁·회계부정 또는 현저한 부당행위
            <br />
            3. 이 단체의 업무를 방해하는 행위
            <br />
            <br />
            제11조 (임원의 임기)
            <br />
            ① 임원의 임기는 3년으로 하고 중임할 수 있다.
            <br />
            ② 보선에 의하여 선임된 임원의 임기는 전임자의 잔여기간으로 한다.
            <br />
            <br />
            제12조 (임원의 직무)
            <br />
            ① 위원장은 이 단체를 대표하고 업무를 통할하며 총회 및 운영위원회의
            의장이 된다.
            <br />
            위원장 유고시에는 미리 운영위원회가 정한 순으로 그 직무를 대행한다.
            <br />
            ② 운영위원은 운영위원회를 통하여 이 단체의 주요 사항을 심의,
            의결하며
            <br />
            운영위원회 또는 위원장으로부터 위임받은 사항을 처리한다.
            <br />
            ③ 감사는 일반회계 및 운영에 대해 감사하며 부정 또는 부당한 점이 있을
            경우
            <br />
            운영위원회에 시정을 요구하고 그 보고를 위하여 운영위원회 또는 총회의
            소집을 요구할 수 있다.
            <br />
            <br />
            <br />
            <br />
            <br />
            <span>제3장 운영위원회 및 총회</span>
            <br />
            제13조 (운영위원회의 구성) ① 위원장과 운영위원으로 구성한다.
            <br />
            <br />
            ② 감사는 운영위원회에 참석하여 발언할 수 있다.
            <br />
            <br />
            제14조 (운영위원회의 소집)
            <br />
            ① 운영위원회는 정기운영위원회 및 임시운영위원회로 구분하며 위원장이
            소집한다.
            <br />
            ② 정기 운영위원회는 매월 1회 소집하며 임시 운영위원회는
            <br />
            위원장, 감사 또는 재적위원 1/3 이상의 서면 요청이 있을 때 소집한다.
            <br />
            <br />
            제15조 (의결정족수) 운영위원회는 재적 위원 과반수의 출석으로
            개의하고
            <br />
            출석위원 과반수의 찬성으로 의결한다.
            <br />
            <br />
            제16조 (총회)
            <br />
            ① 총회는 최고 의결기관으로 전 회원으로 구성하며 정기총회와
            임시총회가 있고 위원장이 소집한다.
            <br />
            ② 정기총회는 매년 1회 회계연도 종료 후 1개월 이내에 소집하며
            <br />
            임시총회는 위원장 또는 감사 및 재적회원 1/3 이상의 서면 요정이 있을
            때 소집한다.
            <br />
            ③ 위원장은 총회의 안건, 일시, 장소 등을 명기하여 회일 7일전까지 서면
            통지하여야 한다.
            <br />
            <br />
            제17조 (의결정족수)
            <br />
            ① 재적 회원 과반수의 출석으로 개회되며 출석회원 과반수의 찬성으로
            의결한다.
            <br />
            ② 총회 의결권은 참석하는 다른 회원에게 서면으로 위임할 수 있다. 이
            경우 위임장은 총회전까지 의장에게 제출하여야 한다.
            <br />
            <br />
            제18조 (총회의 의결사항) 총회는 다음 사항을 심의, 의결한다.
            <br />
            1. 임원의 선출과 해임
            <br />
            2. 단체의 해산 및 정관 변경에 관한 사항
            <br />
            3. 기본재산의 취득, 처분 및 자금 차입에 관한 사항
            <br />
            4. 예산 및 결산의 승인
            <br />
            5. 사업계획의 승인
            <br />
            6. 기타 중요사항
            <br />
            <br />
            제19조 (회의록) 운영위원회 및 총회의 의사 진행 경과와 결과는
            회의록으로 작성해야 하며 의장과 참여 임원이 기명 날인한다.
            <br />
            <br />
            <br />
            <br />
            <br />
            <span>제4장 사무국</span>
            <br />
            제20조 (종사자의 구성 및 임면)
            <br />
            <br />
            ① 이 단체의 업무를 효율적으로 집행하기 위하여 사무국을 두며 필요한
            조직의 각 부서는 운영회의 결의로 정한다.
            <br />
            ② 종사자의 임명에 관하여는 운영위원회 결의로 별도의 인사규정을 두어
            정한다.
            <br />
            <br />
            <br />
            <br />
            <br />
            <span>제5장 회계 및 재정</span>
            <br />
            제21조 (재산의 구분) 이 단체의 재산은 기본재산과 보통재산으로
            구분한다.
            <br />
            1. 기본재산은 이 단체 설립 당시 기본재산으로 출연한 재산과
            운영위원회에서
            <br />
            기본재산으로 편입할 것을 의결할 재산으로 한다.
            <br />
            2. 보통재산은 그 이외의 재산으로 한다.
            <br />
            <br />
            제22조 (수입금) 이 단체의 수입금은 수익사업으로 취득한 수익금,
            회원들의 자발적 후원금 및 기타의 수입으로 한다.
            <br />
            <br />
            제23조 (출자 및 융자) 이 단체의 목적사업을 위해 총회결의로
            외부단체의 출자나 융자를 받을 수 있다.
            <br />
            <br />
            제24조 (회계연도 및 보고)
            <br />
            ① 회계연도는 정부의 회계연도에 준한다.
            <br />
            ② 감사는 회계연도 종료 후 1개월 이내에 전년도 사업실적서 및
            수지결산서를 작성하여 운영위원회 의결을 거쳐 총회에 보고한다.
            <br />
            <br />
            <br />
            <br />
            <br />
            <span>제6장 보칙</span>
            <br />
            제25조 (정관변경) 이 단체의 정관을 변경하고자 할 때에는 총회에서
            재적회원 3분의 2 이상의 찬성으로 의결한다.
            <br />
            <br />
            제26조 (해산 및 합병) 이 단체를 해산하거나 합병하고자 할 때에는
            총회에서 재적회원 3분의 2 이상의 찬성으로 의결한다.
            <br />
            <br />
            제27조 (잔여재산의 귀속) 이 단체를 해산하는 경우 잔여재산은 다른
            비영리단체 또는 공익적 기금에 기부한다.
            <br />
            <br />
            제28조 (운영규정) 이 정관 규정 이외에 이 단체의 운영에 필요한 사항은
            운영위원회 의결로 별도의 규정을 두어 정한다.
            <br />
            <br />
            <span>
              부칙
              <br />
              <br />
              제1조 (시행일) 이 정관은 2022. 06. 16. 부터 시행한다.
              <br />
              <br />
              <br />
              <br />
              <br />
              2022년 6월 16일 운영위원장 대표 이 정 훈
            </span>
          </p>
        </div>

        <div className="folder_dow">
          <p>정관 한글 파일 다운로드</p>
          <a href={file} download="수학기반 산업기술 혁신 연구학회 정관.hwp">
            <button>정관 다운로드</button>
          </a>
        </div>
      </div>
    </div>
  );
}
export default Articles;
