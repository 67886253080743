import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Header, Footer, ScrollToTop } from "./components";

import {
  Main,
  Purpose,
  Chart,
  Articles,
  Code_Of_Ethics,
  Membership,
  Submission_Information,
  Submission_Rules,
  Notice,
  Society_News,
  Research,
  Academic_Conference,
  Academic_Award,
  Post,
  ShowArticles,
} from "./page/index";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Header />
        <Routes>
          <Route path="/" element={<Main />} />

          {/* introduce */}
          <Route path="/introduce/purpose" element={<Purpose />} />
          <Route path="/introduce/chart" element={<Chart />} />
          <Route path="/introduce/articles" element={<Articles />} />
          <Route
            path="/introduce/code_of_ethics"
            element={<Code_Of_Ethics />}
          />

          {/* member */}
          <Route path="/member/membership" element={<Membership />} />

          {/* journal */}
          <Route
            path="/journal/submission_information"
            element={<Submission_Information />}
          />
          <Route
            path="/journal/submission_rules"
            element={<Submission_Rules />}
          />

          {/* notice */}
          <Route path="/notice/notice" element={<Notice />} />
          <Route path="/notice/society_news" element={<Society_News />} />

          {/* article */}
          <Route path="/article/research" element={<Research />} />

          {/* symposium */}
          <Route
            path="/symposium/academic_conference"
            element={<Academic_Conference />}
          />
          <Route
            path="/symposium/academic_award"
            element={<Academic_Award />}
          />

          {/* post */}
          <Route path="/post/:type/:id" element={<Post />} />

          {/* articles */}
          <Route path="/showArticles/:id" element={<ShowArticles />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
