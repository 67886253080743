const noticeData = [
  {
    id: 1,
    title: "공지사항 안내입니다. 이용해주셔서 감사합니다",
    date: "2022.06.15",
    description: "업데이트 예정 입니다",
  },
  {
    id: 2,
    title: "[공지사항] 새롭게 리뉴얼된 페이지를 만나보세요.",
    date: "2023.01.13",
    description: "업데이트 예정 입니다",
  },
];

export default noticeData;
