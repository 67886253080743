import react, { useState } from "react";
import { BackToTop, Sub_Banner, Sub_Path } from "../../components";
import "../../style/pages/notice/society_news.scss";
import { Link } from "react-router-dom";

const society_news = [
  {
    id: 1,
    title: "[공지사항] 업데이트 예정 입니다.",
    date: "2023.01.13",
  },
];

function Society_News() {
  const [searchText, setSearchText] = useState("");

  const handleInputChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    console.log("검색어 :", searchText);
  };

  const filteredNews = society_news.filter((news) =>
    news.title.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <div className="Society_News_Box">
      <BackToTop />
      <Sub_Banner />
      <Sub_Path destination={"학회소식"} />

      <div className="content_box">
        <div className="title_pur">학회소식</div>
        <section className="notice">
          <div className="page-title">
            <div className="container">
              <h3>학회소식 게시판</h3>
            </div>
          </div>

          <div className="board-search">
            <div className="container">
              <div className="search-window">
                <form onSubmit={handleSearchSubmit}>
                  <div className="search-wrap">
                    <input
                      className="search"
                      type="search"
                      placeholder="검색어를 입력해주세요."
                      value={searchText}
                      onChange={handleInputChange}
                    />
                    <button type="submit" className="btn btn-dark">
                      검색
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="board-list">
            <div className="container">
              <table className="board-table">
                <thead>
                  <tr>
                    <th scope="col" className="th-num">
                      번호
                    </th>
                    <th scope="col" className="th-title">
                      제목
                    </th>
                    <th scope="col" className="th-date">
                      등록일
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredNews.map((news) => (
                    <tr key={news.id}>
                      <td>{news.id}</td>
                      <th>
                        <Link to={`/post/society_news/${news.id}`}>
                          <a>{news.title}</a>
                        </Link>
                      </th>
                      <td>{news.date}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
export default Society_News;
