import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";

import "../../style/pages/introduce/purpose.scss";

import { BackToTop, Sub_Banner, Sub_Path } from "../../components";

function PurPose() {
  let navigate = useNavigate();

  return (
    <div className="Purpose">
      <BackToTop />
      <Sub_Banner />
      <Sub_Path destination="설립목적" />

      <div className="Title_Box">
        <div className="Title_Pur">학회소개</div>
        <div className="Purpose">
          <h1>설립 목적</h1>
          <p>
            본 학회는 암호 스키마 및 알고리즘 연구에 대한 학술 및 기술의 진흥을
            위하여
            <br />
            전문가와 비전문가를 따지지 않고 관련 연구에 대한 학문적 교류의 장을
            만들고자 함을 목표로 하고,
            <br />
            이를 위하여 학술적 연구와 필요한 사업을 행함을 목적으로
            창립되었습니다.
            <br />
            <br />
            <span>
              본 회는 상기의 목적을 달성하기 위하여 다음 각호의 사업을 행하고
              있습니다.
              <br />
              <br />
              1. 산업기술에 관한 수학적 학술연구 세미나 주최
              <br />
              2. 학술적, 기술적 연구조사 및 발간사업
              <br />
              3. 표준 및 규격의 제정에 관한 연구
              <br />
              4. 기술의 보급, 발전을 위한 사업
              <br />
              5. 전문가 양성을 위한 기반 조성 사업
              <br />
            </span>
          </p>
        </div>

        <div className="Greetings">
          <span>인사말</span>
          <p>
            안녕하세요. 수학기반 산업기술 혁신 연구학회 회장 이정훈입니다.
            <br />
            저희 학회는 다양한 분야가 융합 된 수학기반의 연구를 진행하기 위하여
            전공자, 비전공자를 구분하지 않고
            <br />
            학술적 교류의 장을 만들기 위하여 설립되었습니다.
            <br />
            또한 각 분야의 전문가들에게 수학의 교육의 장을 만들어
            <br />
            수학이라는 언어를 통하여 보다 명확하고 확실한 정보를
            <br />
            연구할 수 있는 기회를 마련할 예정입니다.
            <br />
            감사합니다.
          </p>
        </div>
      </div>
    </div>
  );
}
export default PurPose;
