import React, { useState } from "react";
import {
  BackToTop,
  Sub_Banner,
  Sub_Path,
  ResearchItem,
} from "../../components";

import researchData from "../../assets/object/researchData.js";
import "../../style/pages/article/research.scss";

const ResearchList = ({ data }) => {
  return (
    <div className="listBox">
      {data.map((item) => (
        <ResearchItem key={item.id} item={item} />
      ))}
    </div>
  );
};

const Research = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchType, setSearchType] = useState("제목");
  const [activeTab, setActiveTab] = useState("tab01");

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleSearchTypeChange = (e) => {
    setSearchType(e.target.value);
  };

  const filterData = () => {
    return researchData.filter((item) => {
      const matchesTab = (tab) => {
        switch (tab) {
          case "tab01":
            return true;
          case "tab02":
            return item.specify === "Mathmatics";
          case "tab03":
            return item.specify === "Crypto";
          case "tab04":
            return item.specify === "Blockchain";
          case "tab05":
            return item.specify === "AI"
        }
      };

      const matchesSearchTerm = (item) => {
        const lowerSearchTerm = searchTerm.toLowerCase();
        switch (searchType) {
          case "제목":
            return item.title.toLowerCase().includes(lowerSearchTerm);
          case "본문":
            return item.mainBody.toLowerCase().includes(lowerSearchTerm);
          case "제목+본문":
            return (
              item.title.toLowerCase().includes(lowerSearchTerm) ||
              item.mainBody.toLowerCase().includes(lowerSearchTerm)
            );
          case "작성자":
            return item.author.toLowerCase().includes(lowerSearchTerm);
        }
      };

      const matchesStartDate = startDate
        ? new Date(item.publicationDate) >= new Date(startDate)
        : true;
      const matchesEndDate = endDate
        ? new Date(item.publicationDate) <= new Date(endDate)
        : true;

      return (
        matchesTab(activeTab) &&
        matchesSearchTerm(item) &&
        matchesStartDate &&
        matchesEndDate
      );
    });
  };

  return (
    <div className="Article_Box">
      <BackToTop />
      <Sub_Banner />
      <Sub_Path destination={"Article"} />
      <div className="content_box">
        <div className="search_back">
          <div className="search_div">
            <p>논문 정보 검색</p>
            <div className="searchBox">
              <label>
                <select
                  className="select"
                  value={searchType}
                  onChange={handleSearchTypeChange}
                >
                  <option value="제목">제목</option>
                  <option value="본문">본문</option>
                  <option value="제목+본문">제목+본문</option>
                  <option value="작성자">작성자</option>
                  <option value="자료유형">자료유형</option>
                </select>
                <input
                  type="text"
                  className="search"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  placeholder="검색어를 입력해 주세요."
                />
              </label>
            </div>
            <div className="date_dd">
              <input
                type="datetime-local"
                name="startDate"
                value={startDate}
                onChange={handleStartDateChange}
              />
              <span>~</span>
              <input
                type="datetime-local"
                name="endDate"
                value={endDate}
                onChange={handleEndDateChange}
              />
            </div>
          </div>
        </div>

        <article className="Research_sub_text">
          <h1>MITI Article</h1>
          <p>
            MITI는 산업 기술에 대한 학술적 연구를 진행하고 이에 대한 오픈
            액세스하는 저널입니다.
            <br />
            산업 기술과 관련된 수학적 연구를 위한 고급 포럼을 제공하며 MITI에서
            온라인으로 분기별로 게시합니다.
          </p>
        </article>

        <div className="tab">
          <ul className="tabnav">
            <li>
              <div
                className={activeTab === "tab01" ? "active" : ""}
                onClick={() => handleTabClick("tab01")}
              >
                전체보기
              </div>
            </li>
            <li>
              <div
                className={activeTab === "tab02" ? "active" : ""}
                onClick={() => handleTabClick("tab02")}
              >
                수학
              </div>
            </li>
            <li>
              <div
                className={activeTab === "tab03" ? "active" : ""}
                onClick={() => handleTabClick("tab03")}
              >
                암호학
              </div>
            </li>
            <li>
              <div
                className={activeTab === "tab04" ? "active" : ""}
                onClick={() => handleTabClick("tab04")}
              >
                블록체인
              </div>
            </li>
            <li>
              <div
                className={activeTab === "tab05" ? "active" : ""}
                onClick={() => handleTabClick("tab05")}
              >
                AI
              </div>
            </li>
          </ul>
          <div className="tabcontent">
            <ResearchList data={filterData()} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Research;
