/* eslint-disable jsx-a11y/anchor-is-valid */
import "../../style/component/ResearchItem.scss";
import { preview, down } from "../../assets/image";
import { Link } from "react-router-dom";

const ResearchItem = ({ item }) => {
  const handleClick = (e) => {
    e.preventDefault();

    const linkOpen = document.createElement("a");
    linkOpen.href = item.file;
    linkOpen.target = "_blank";
    linkOpen.click();

    const linkDownload = document.createElement("a");
    linkDownload.href = item.file;
    linkDownload.download = item.title;
    linkDownload.click();
  };

  return (
    <div className="listInner">
      <div className="imgInner">
        <p>No.{item.id}</p>
        <img src={item.thumbnail} alt="" />
      </div>

      <div className="contentContainer">
        <p className="title">{item.title}</p>
        <p className="serve_title">{item.subTitle}</p>

        <div className="description">
          <div className="leftDescription">
            <p className="publication_date">
              발간날짜 : {item.publicationDate}
            </p>
            <p className="data_type">e-ISBN : {item.ISBN}</p>
            <p className="subject_classification">
              주제분류 : {item.subjectClassification}
            </p>
          </div>

          <div className="rightDescription">
            <p className="organization">발간기관 : {item.organization}</p>
            <p className="number">등재번호 : {item.registrationNumber}</p>
          </div>
        </div>

        <div className="btn">
          <a className="Download" onClick={handleClick}>
            <img src={down} alt="" className="icon_img" />
            다운로드
          </a>
          <Link to={`/showArticles/${item.id}`} className="Preview">
            <img src={preview} alt="" className="icon_img" />
            미리보기
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ResearchItem;
