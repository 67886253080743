import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";

import "../../style/pages/introduce/code_of_ethics.scss";

import { BackToTop, Sub_Banner, Sub_Path } from "../../components";

function Code_Of_Ethics() {
  return (
    <div className="Code_Of_Ethics">
      <BackToTop />
      <Sub_Banner />
      <Sub_Path destination={"연구윤리규정"} />

      <div className="Code-Of-Ethics_Box">
        <div className="Content_Box">
          <div className="Title_Pur">연구윤리규정</div>
          <div className="Content">
            <header>
              <h1>제1 절 총 칙</h1>
              <p>제정 2022. 6. 19</p>
            </header>
            <p className="Content_P">
              제1 조(연구윤리규정의 목적) 본 규정은 수학기반 산업기술 혁신
              연구학회의
              <br />
              연구윤리 및 진실성을 확보하는데 필요한 역할과 책임에 관하여
              기본적인 원칙과 방향을 제시함을 목적으로 한다.
              <br />
              <br />
              제2 조(적용대상 및 범위) 본 규정은 수학기반 산업기술 혁신
              연구학회의
              <br />
              회원 및 수학기반 산업기술 혁신 연구학회가 주관하는 국내ㆍ외 모든
              사업 및 학술 활동 관련자에게 적용된다.
              <br />
              <br />
              제3 조(연구부정행위의 범위)
              <br />
              ① 본 규정에서 제시하는 연구부정행위(이하 ”부정행위“라 한다)는
              연구의 제안, 연구의 수행,
              <br />
              연구결과의 보고 및 발표 등에서 행하여진 위조ㆍ변조ㆍ표절, 부당한
              논문저자 표시,
              <br />
              중복게재, 공적 허위진술 등을 말하며 다음 각 호와 같다.
              <br />
              1. “위조”는 존재하지 않는 데이터 또는 연구결과 등을 허위로 만들어
              내는 행위를 말한다.
              <br />
              2. “변조”는 연구 재료ㆍ장비ㆍ과정 등을 인위적으로 조작하거나
              데이터를
              <br />
              임의로 변형ㆍ삭제함으로써 연구 내용 또는 결과를 왜곡하는 행위를
              말한다.
              <br />
              3. “표절”이라 함은 타인의 아이디어, 연구내용ㆍ결과 등을 정당한
              승인 또는 인용 없이 도용하는 행위를 말한다.
              <br />
              4. “부당한 논문저자 표시”는 연구내용 또는 결과에 대하여
              과학적ㆍ기술적 공헌 또는 기여를 한 사람에게
              <br />
              정당한 이유 없이 논문저자 자격을 부여하지 않거나, 과학적ㆍ기술적
              공헌 또는 기여를 하지 않은 자에게 감사의 표시 또는 예우 등을
              이유로 논문저자 자격을 부여하는 행위를 말한다.
              <br />
              5. “중복게재”는 동일한 내용의 논문을 두 개 이상의 학술지에 게재한
              행위를 말한다.
              <br />
              6. “공적 허위진술”는 본인의 학력, 경력, 자격, 연구업적 및 결과
              등에 관해서 허위 진술하는 행위를 말한다.
              <br />
              7. 본인 또는 타인의 부정행위 혐의에 대한 조사를 고의로 방해하거나
              제보자에게 위해를 가하는 행위
              <br />
              8. 과학기술계에서 통상적으로 용인되는 범위를 심각하게 벗어난 행위
              등 제1항의 규정에
              <br />
              의한 부정행위 외에도 자체적인 조사 또는 예방이 필요하다고 판단되는
              부정행위를 제11조 제1항의 내용에 포함시킬 수 있다.
            </p>
            <br />
            <br />
            <br />
            <header>
              <h2>제2 절 연구관련 윤리규정</h2>
            </header>
            <p className="Content_P">
              제4 조(저자가 지켜야 할 윤리규정)
              <br />
              ① 저자는 본 규정에서 제시하는 연구부정행위에 대해서 책임을
              져야한다.
              <br />
              부정행위는 제3조에서와 같이 연구의 제안, 연구의 수행, 연구결과의
              보고 및 발표 등에서
              <br />
              행하여진 위조ㆍ변조ㆍ표절, 부당한 논문저자 표시, 중복게재, 공적
              허위진술 등을 말한다.
              <br />
              <br />
              제5 조(편집위원이 지켜야 할 윤리규정)
              <br />
              ① 편집위원은 투고된 논문의 게재 여부를 결정하는 모든 책임을 지며,
              <br />
              저자의 인격과 학자로서의 독립성을 존중해야 한다. <br />
              ② 편집위원은 학술지 게재를 위해 투고된 논문을 저자의 성별, 나이,
              소속 기관은 물론이고 어떤 선입견이나
              <br />
              사적인 친분과도 무관하게 오로지 논문의 질적 수준과 투고규정에
              근거하여 공평하게 취급하여야 한다. <br />
              ③ 편집위원은 투고된 논문의 평가를 해당 분야의 전문적 지식과 공정한
              판단 능력을 지닌 심사위원에게 의뢰해야 한다.
              <br />
              심사 의뢰 시에는 저자와 지나치게 친분이 있거나 지나치게 적대적인
              심사위원을 피함으로써
              <br />
              가능한 한 객관적인 평가가 이루어질 수 있도록 노력한다.
              <br />
              단, 같은 논문에 대한 평가가 심사위원 간에 현저하게 차이가 날
              경우에는 해당 분야 제3의 전문가에게 자문을 받을 수 있다.
              <br />
              ④ 편집위원은 투고된 논문의 게재가 결정될 때까지는 심사자 이외의
              사람에게 저자에 대한 사항이나 논문의 내용을 공개하면 안 된다.
              <br />
              <br />
              제6 조(심사위원이 지켜야 할 윤리규정) <br />
              ① 심사위원은 학술지의 편집위원(회)이 의뢰하는 논문을 심사규정이
              정한 기간 내에
              <br />
              성실하게 평가하고 평가 결과를 편집위원(회)에게 통보해 주어야 한다.
              <br />
              만약 자신이 논문의 내용을 평가하기에 적임자가 아니라고 판단될
              경우에는 편집위원(회)에게 지체 없이 그 사실을 통보한다.
              <br />
              ② 심사위원은 논문을 개인적인 학술적 신념이나 저자와의 사적인 친분
              관계를 떠나 객관적 기준에 의해 공정하게 평가하여야 한다.
              <br />
              충분한 근거를 명시하지 않은 채 논문을 탈락시키거나, 심사자 본인의
              관점이나 해석과 상충된다는 이유로 논문을 탈락시켜서는 안 되며
              <br />
              심사 대상 논문을 제대로 읽지 않은 채 평가해서도 안 된다. <br />
              ③ 심사위원은 전문 지식인으로서의 저자의 인격과 독립성을 존중하여야
              한다.
              <br />
              평가의견서에는 논문에 대한 자신의 판단을 밝히되, 보완이 필요하다고
              생각되는 부분에 대해서는
              <br />
              그 이유도 함께 상세하게 설명해야 한다. 가급적 정중하고 부드러운
              표현을 사용하고, 저자를 비하하거나 모욕적인 표현은 삼가한다.
              <br />
              ④ 심사위원은 심사 대상 논문에 대한 비밀을 지켜야 한다.
              <br />
              논문 평가를 위해 특별히 조언을 구하는 경우가 아니라면 논문을 다른
              사람에게 보여주거나
              <br />
              논문 내용을 놓고 다른 사람과 논의하는 것도 바람직하지 않다.
              <br />
              또한 논문이 게재된 학술지가 출판되기 전에 저자의 동의 없이 논문의
              내용을 인용해서는 안 된다.
            </p>
            <br />
            <br />
            <br />
            <header>
              <h2>제3 절 연구윤리규정 시행지침</h2>
            </header>
            <p className="Content_P">
              제7 조(연구윤리규정 서약) 수학기반 산업기술 혁신 연구학회는 회원의
              연구 및 사업 수행시 준수해야할 연구윤리규정을 홍보하며,
              <br />
              신입 회원은 본 윤리규정을 준수하기로 서약해야 한다. 기존회원은
              윤리규정의 발효시 윤리규정을 준수하기로 서약한 것으로 간주한다.
              <br />
              <br />
              제8 조(연구윤리위원회 구성) <br />
              ① 연구윤리위원회는 회원 5인 이상의 위원으로 구성함을 원칙으로
              하며, 위원은 본 학회 회원으로서 회장이 임명한다.
              <br />
              ② 본조사 착수 이전에 제보자에게 제1항의 규정에 의한 연구윤리위원
              명단을 알려야 하며,
              <br />
              제보자가 연구윤리위원 기피에 관한 정당한 이의를 제기할 경우 이를
              수용하여야 한다.
              <br />
              <br />
              제9 조(연구윤리위원회 권한)
              <br />
              ①연구윤리위원회는 연구윤리규정 위반으로 보고된 사안에 대하여
              제보자, 피조사자, 증인, 참고인에 대하여
              <br />
              진술을 위한 출석을 요구할 수 있으며, 피조사자에게 자료의 제출을
              요구할 수 있다. 이 경우 피조사자는 반드시 응하여야 한다.
              <br />
              ② 연구윤리위원회는 소속기관의 장에게 사실로 판정된 부정행위에
              대하여 이를 통보할 수 있다. <br />
              <br />
              제10 조(학회의 권한과 역할) 수학기반 산업기술 혁신 연구학회는
              연구에 대한 부정행위 신고 접수창구를 마련하고,
              <br />
              학회에서 진실성 검증이 공정하고 합리적으로 이루어졌는지에 대해
              심사하며, 이를 토대로 후속조치를 실시한다.
              <br />
              <br />
              제11 조(자체검증체계 마련) <br />
              ①수학기반 산업기술 혁신 연구학회는 본 규정 내용을 기초로 학회
              실정에 적합한 연구진실성 검증에 관한 자체규정을
              <br />
              다음 각 호의 사항을 포함하여 마련ㆍ운영한다.
              <br />
              1. 부정행위의 범위
              <br />
              2. 부정행위 신고 접수 및 조사 등을 담당하는 기구, 부서 또는 책임자
              <br />
              3. 연구윤리와 관련된 조사의 수행을 위한 연구윤리위원회의 구성
              원칙, 조사 절차 및 기간
              <br />
              4. 부정행위에 대한 제재의 종류
              <br />
              5. 제보자 및 피조사자 보호방안
            </p>
            <br />
            <br />
            <br />
            <header>
              <h2>제4 절 연구진실성 검증 절차와 기준</h2>
            </header>
            <p className="Content_P">
              제12 조(진실성 검증 책임주체) 부정행위의 발생을 인지하거나 제보가
              있을 경우 수학기반 산업기술 혁신 연구학회는 이에 대한
              <br />
              검증 책임이 있으며, 회장은 연구윤리위원회를 통하여 성실하게
              처리하여야 한다.
              <br />
              단, 피조사자가 조사위원회에서 요구하는 자료를 고의로 훼손하였거나
              제출을 거부하는 경우에 요구 자료에 포함되어 있다고
              <br />
              인정되는 내용의 진실성을 입증할 책임은 피조사자에게 있다.
              <br />
              <br />
              제13 조(본조사) <br />
              ① 본조사는 부정행위의 혐의에 대한 사실 여부를 입증하기 위한 절차를
              말하며,
              <br />
              제8조의 규정에 따라 연구윤리위원회 등을 구성하여 진행하여야 한다.
              <br />
              ② 연구윤리위원회는 제보자와 피조사자에게 의견진술의 기회를 주어야
              하며,
              <br />
              본조사결과에 대해서도 이의제기 및 변론의 기회를 주어야 한다.
              당사자가 이에 응하지 않을 경우에는 이의가 없는 것으로 간주한다.{" "}
              <br />
              ③ 본조사결과에 대한 제보자와 피조사자의 이의제기 또는 변론 내용과
              그에 대한 처리결과는 조사결과 보고서에 포함되어야 한다. <br />
              <br />
              제14 조(조사결과의 보고) <br />
              ① 연구윤리위원회는 본조사의 결과와 내용을 10일 이내에
              연구지원기관에 보고하여야 한다.
              <br />
              ② 본조사의 결과보고서에는 다음 각 호의 사항이 반드시 포함되어야
              한다.
              <br />
              1. 제보의 내용 <br />
              2. 조사의 대상이 된 부정행위
              <br />
              3. 연구윤리위원회의 조사위원 명단
              <br />
              4. 관련 증거 및 증인
              <br />
              5. 해당 연구에서의 피조사자의 역할과 혐의의 사실 여부
              <br />
              6. 조사결과에 대한 제보자와 피조사자의 이의제기 또는 변론 내용과
              그에 대한 처리결과
              <br />
              <br />
              제15 조(조사결과 보고에 대한 후속조치) <br />
              ① 회장은 제14조 제1항의 규정에 의하여 보고를 받은 경우
              조사내용ㆍ결과 및 그에 따른 판정의 합리성과
              <br />
              타당성에 문제가 있다고 판단되는 경우 연구윤리위원회에 추가적인
              조사의 실시 또는 조사와 관련된 자료의 제출을 요구할 수 있다.
              <br />
              ② 수학기반 산업기술 혁신 연구학회는 판정결과에 근거하여 후속
              조치를 취하고 이를 피조사자에게 통보하여야 한다.
              <br />
              1. 연구부정행위 관련자에 대한 소속기관에의 징계의 요구
              <br />
              2. 3년 이하의 학회 회원자격 정지
              <br />
              3. 학회지 및 인터넷 매체를 통한 공지 <br />
              4. 그 밖의 부정행위의 규모ㆍ범위 등을 고려한 별도의 조치 사항
              <br />
              ③ 제2항 2호의 회원자격 정지라 함은 다음 각 호의 사항에 대한 제한을
              말한다.
              <br />
              1. 학회지 논문 게재
              <br />
              2. 학회 활동 참여
              <br />
              3. 논문의 직권 및 인용
              <br />
              4. 기타 회원으로서 가지는 권리
              <br />
              <br />
              제16 조(기타)본 규정에서 규정하지 않은 사항은 수학기반 산업기술
              혁신 연구학회의 관련 내규에 따르고,
              <br />그 이외의 사항은 수학기반 산업기술 혁신 연구학회 이사회에서
              논의하여 결정한다.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Code_Of_Ethics;
