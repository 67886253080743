import React, { useState, useEffect } from "react";
import "../style/Back-To-Top.scss";

function BackToTop() {
  const [showButton, setShowButton] = useState(false);

  const scrollToTop = () => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const ShowButtonClick = () => {
      if (window.scrollY > 100) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };
    window.addEventListener("scroll", ShowButtonClick);
    return () => {
      window.removeEventListener("scroll", ShowButtonClick);
    };
  }, []);

  return (
    <>
      <div
        className={`Back-To-Top ${showButton ? "visible" : ""}`}
        onClick={scrollToTop}
      >
        TOP
      </div>
    </>
  );
}

export default BackToTop;
