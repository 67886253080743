import React, { useState, useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { log } from "../assets/image/index";
import "../style/Header.scss";

function Header() {
  let navigate = useNavigate();
  const location = useLocation();
  const [dropDown, setDropDown] = useState("");

  useEffect(() => {
    setDropDown("");
  }, [location]);

  if (location.pathname.match(/^\/showArticles(\/\d+)?$/)) return null;

  return (
    <div className="Header">
      <div className="Logo_Box">
        <Link to="/">
          <img src={log} className="Logo" alt="" />
        </Link>
      </div>
      <div className="Menu_Bar">
        <div
          className="Btn"
          onMouseEnter={() => setDropDown(0)}
          onMouseLeave={() => setDropDown("")}
        >
          Introduce
          {dropDown === 0 && (
            <div className="DropDown">
              <Link to="/introduce/purpose">
                <div>설립목적</div>
              </Link>
              <Link to="/introduce/chart">
                <div>학회 조직도</div>
              </Link>
              <Link to="/introduce/articles">
                <div>정관</div>
              </Link>
              <Link to="/introduce/code_of_ethics">
                <div>연구윤리규정</div>
              </Link>
            </div>
          )}
        </div>

        <div
          className="Btn"
          onMouseEnter={() => setDropDown(1)}
          onMouseLeave={() => setDropDown("")}
        >
          Member
          {dropDown === 1 && (
            <div className="DropDown">
              <Link to="/member/membership">
                <div>회원가입</div>
              </Link>
            </div>
          )}
        </div>
        <div
          className="Btn"
          onMouseEnter={() => setDropDown(2)}
          onMouseLeave={() => setDropDown("")}
        >
          Journal
          {dropDown === 2 && (
            <div className="DropDown">
              <Link to="/journal/submission_information">
                <div>투고안내</div>
              </Link>
              <Link to="/journal/submission_rules">
                <div>투고규정</div>
              </Link>
            </div>
          )}
        </div>
        <div
          className="Btn"
          onMouseEnter={() => setDropDown(3)}
          onMouseLeave={() => setDropDown("")}
        >
          Symposium
          {dropDown === 3 && (
            <div className="DropDown">
              <Link to="/symposium/academic_conference">
                <div>정기학술대회</div>
              </Link>
              <Link to="/symposium/academic_award">
                <div>MITI 연구학회상</div>
              </Link>
            </div>
          )}
        </div>
        <div
          className="Btn"
          onMouseEnter={() => setDropDown(4)}
          onMouseLeave={() => setDropDown("")}
        >
          Notice
          {dropDown === 4 && (
            <div className="DropDown">
              <Link to="/notice/notice">
                <div>공지사항</div>
              </Link>

              <Link to="/notice/society_news">
                <div>학회소식</div>
              </Link>
            </div>
          )}
        </div>
        <div
          className="Btn"
          onMouseEnter={() => setDropDown(5)}
          onMouseLeave={() => setDropDown("")}
        >
          Article
          {dropDown === 5 && (
            <div className="DropDown">
              <Link to="/article/research">
                <div>Article</div>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Header;
