import React, { useState } from "react";
import { BackToTop, Sub_Banner, Sub_Path } from "../../components";
import "../../style/pages/symposium/academic_conference.scss";
import { Link } from "react-router-dom";
import AcademicConferenceData from "../../assets/object/academicConferenceData.js";

function Academic_Conference() {
  const [searchText, setSearchText] = useState("");

  const handleInputChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    console.log("검색어 :", searchText);
  };

  const filteredNotices = AcademicConferenceData.filter((notice) =>
    notice.title.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <div className="Academic_Conference_Box">
      <BackToTop />
      <Sub_Banner />
      <Sub_Path destination={"정기학술대회"} />

      <div className="content_box">
        <div className="title_pur">정기학술대회</div>
        <section className="notice">
          <div className="page-title">
            <div className="container">
              <h3>정기학술대회 게시판</h3>
            </div>
          </div>

          <div className="board-search">
            <div className="container">
              <div className="search-window">
                <form onSubmit={handleSearchSubmit}>
                  <div className="search-wrap">
                    <input
                      className="search"
                      type="search"
                      placeholder="검색어를 입력해주세요."
                      value={searchText}
                      onChange={handleInputChange}
                    />
                    <button type="submit" className="btn btn-dark">
                      검색
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="board-list">
            <div className="container">
              <table className="board-table">
                <thead>
                  <tr>
                    <th scope="col" className="th-num">
                      번호
                    </th>
                    <th scope="col" className="th-title">
                      제목
                    </th>
                    <th scope="col" className="th-date">
                      등록일
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredNotices.map((notice) => (
                    <tr key={notice.id}>
                      <td>{notice.id}</td>
                      <th>
                        <Link to={`/post/academic_conference/${notice.id}`}>
                          <a>{notice.title}</a>
                        </Link>
                      </th>
                      <td>{notice.date}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Academic_Conference;
