import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";

import "../../style/pages/introduce/chart.scss";
import { chart } from "../../assets/image";

import { BackToTop, Sub_Banner, Sub_Path } from "../../components";

function Chart() {
  let navigate = useNavigate();
  return (
    <div className="Chart">
      <BackToTop />
      <Sub_Banner />
      <Sub_Path destination={"학회 조직도"} />

      <div className="Chart_Box">
        <div className="Title_Pur">학회 조직도</div>
        <img src={chart} alt="" />
      </div>
    </div>
  );
}
export default Chart;
