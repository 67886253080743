import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import {
  BackToTop,
  Sub_Banner,
  Sub_Path,
  ResearchItem,
} from "../../components";

import "../../style/pages/post/post.scss";
import noticeData from "../../assets/object/noticeData.js";
import SocietyNewsData from "../../assets/object/societyNewsData.js";
import AcademicConferenceData from "../../assets/object/academicConferenceData.js";

const Post = () => {
  const { type, id } = useParams();
  const [post, setPost] = useState(null);

  useEffect(() => {
    let postData = null;
    switch (type) {
      case "notice":
        postData = noticeData.find((notice) => notice.id === parseInt(id));
        break;
      case "society_news":
        postData = SocietyNewsData.find((news) => news.id === parseInt(id));
        break;
      case "academic_conference":
        postData = AcademicConferenceData.find(
          (news) => news.id === parseInt(id)
        );
        break;
      default:
        postData = null;
    }
    setPost(postData);
  }, [id, type]);

  if (!post) {
    return <></>;
  }

  return (
    <div className="Post-Box">
      <BackToTop />
      <Sub_Banner />
      <Sub_Path destination={"게시글"} />
      <div className="content_box">
        <div className="title_box">
          <p className="title">{post.title}</p>
          <div className="description_box">
            <div className="description">작성자 : 운영자</div>
            <div className="description">작성일 : {post.date}</div>
          </div>
        </div>
        <div className="body">
          <p className="bodyText">{post.description}</p>
        </div>
      </div>
    </div>
  );
};

export default Post;
