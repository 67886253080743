import { Link } from "react-router-dom";
import "../style/Sub_Path.scss";

function Sub_Path({ destination }) {
  return (
    <div className="Sub_Path">
      <ul>
        <Link to="/">
          <li>홈</li>
        </Link>
        <li>&gt;</li>
        <Link to="/">
          <li>{destination}</li>
        </Link>
      </ul>
    </div>
  );
}

export default Sub_Path;
