import { BackToTop, Sub_Banner, Sub_Path } from "../../components";

import "../../style/pages/journal/submission_information.scss";

function Submission_Infromation() {
  return (
    <div className="submission_information_Box">
      <Sub_Banner />
      <Sub_Path destination={"투고안내"} />
      <BackToTop />

      <div className="content_box">
        <div className="title_pur">투고안내</div>
        <div className="content">
          <header>
            <p>투고안내</p>
          </header>
          <p className="content-p">
            학술지 투고시, 다음의 사항을 참고하시면 보다 신속한 출판이
            가능합니다.
            <br />
            <br />
            1. 논문의 첫 페이지에 논문제목, 초록(Abstract), 저자명을 쓰고,
            마지막 페이지의 끝에 저자의 주소와 E-mail주소를 쓴다.
            <br />
            <br />
            2. 제목은 간단명료하게 쓰고 초록은 300단어 이내의 길이와 적어도
            하나의 완성된 문장으로 쓴다.
            <br />
            <br />
            3. 다음과 같이 주제분류표(Subject Classification)를 참조하여
            <br />첫 페이지 하단에 분류번호와 Key words and phrases를 쓴다.
          </p>

          <header>
            <h3>&#60;주제분류표&#62;</h3>
          </header>

          <table className="tg">
            <thead>
              <tr>
                <th>Main Category</th>
                <th>Subcategory</th>
                <th>Classification number</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Algebra(01)</td>
                <td>Order, lattices, ordered algebraic structures</td>
                <td>01-01</td>
              </tr>
              <tr>
                <td>Algebra(01)</td>
                <td>Field theory and polynomials</td>
                <td>01-02</td>
              </tr>
              <tr>
                <td>Algebra(01)</td>
                <td>Algebraic geometry</td>
                <td>01-03</td>
              </tr>
              <tr>
                <td>Algebra(01)</td>
                <td>Linear and multilinear algebra; matrix theory</td>
                <td>01-04</td>
              </tr>
              <tr>
                <td>Algebra(01)</td>
                <td>Group theory and generalizations</td>
                <td>01-05</td>
              </tr>
              <tr>
                <td>Algebra(01)</td>
                <td>General algebraic systems</td>
                <td>01-06</td>
              </tr>
              <tr>
                <td>Algebra(01)</td>
                <td>Category theory; homological algebra</td>
                <td>01-07</td>
              </tr>
              <tr>
                <td>Algebra(01)</td>
                <td>Ring theory</td>
                <td>01-08</td>
              </tr>
              <tr>
                <td>Algebra(01)</td>
                <td>Topological groups, Lie groups</td>
                <td>01-09</td>
              </tr>
              <tr>
                <td>
                  Number
                  <br />
                  theory(02)
                </td>
                <td>lgebraic number theory</td>
                <td>02-01</td>
              </tr>
              <tr>
                <td>
                  Number
                  <br />
                  theory(02)
                </td>
                <td>Analytic number theory</td>
                <td>02-02</td>
              </tr>
              <tr>
                <td>Analysis(03)</td>
                <td>Measure and integration</td>
                <td>03-01</td>
              </tr>
              <tr>
                <td>Analysis(03)</td>
                <td>Several complex variables and analytic spaces</td>
                <td>03-02</td>
              </tr>
              <tr>
                <td>Analysis(03)</td>
                <td>Special functions</td>
                <td>03-03</td>
              </tr>
              <tr>
                <td>Analysis(03)</td>
                <td>Approximations and expansions</td>
                <td>03-04</td>
              </tr>
              <tr>
                <td>Analysis(03)</td>
                <td>Harmonic analysis</td>
                <td>03-05</td>
              </tr>
              <tr>
                <td>Analysis(03)</td>
                <td>Integral transforms, operational calculus</td>
                <td>03-06</td>
              </tr>
              <tr>
                <td>Analysis(03)</td>
                <td>Functional analysis</td>
                <td>03-07</td>
              </tr>
              <tr>
                <td>Analysis(03)</td>
                <td>Operator theory</td>
                <td>03-08</td>
              </tr>
              <tr>
                <td>Analysis(03)</td>
                <td>
                  Calculus of variations and optimal control; optimization
                </td>
                <td>03-09</td>
              </tr>
              <tr>
                <td>Analysis(03)</td>
                <td>Numerical analysis</td>
                <td>03-10</td>
              </tr>
              <tr>
                <td>
                  Differential <br />
                  geometry &<br />
                  Topology(04)
                </td>
                <td>General topology</td>
                <td>04-01</td>
              </tr>
              <tr>
                <td>
                  Differential <br />
                  geometry &<br />
                  Topology(04)
                </td>
                <td>Algebraic topology</td>
                <td>04-02</td>
              </tr>
              <tr>
                <td>
                  Differential <br />
                  geometry &<br />
                  Topology(04)
                </td>
                <td>Differential geometry</td>
                <td>04-03</td>
              </tr>
              <tr>
                <td>
                  Differential <br />
                  geometry &<br />
                  Topology(04)
                </td>
                <td>Manifolds and cell complexes</td>
                <td>04-04</td>
              </tr>
              <tr>
                <td>Statistics(05)</td>
                <td>Probability theory and stochastic processes</td>
                <td>05-01</td>
              </tr>
              <tr>
                <td>Statistics(05)</td>
                <td>Statistics</td>
                <td>05-02</td>
              </tr>
              <tr>
                <td>
                  Artificial
                  <br />
                  Intelligence(06)
                </td>
                <td>Data mining</td>
                <td>06-01</td>
              </tr>
              <tr>
                <td>
                  Artificial
                  <br />
                  Intelligence(06)
                </td>
                <td>Machine learning</td>
                <td>06-02</td>
              </tr>
              <tr>
                <td>
                  Artificial
                  <br />
                  Intelligence(06)
                </td>
                <td>Deep learning</td>
                <td>06-03</td>
              </tr>
              <tr>
                <td>Security(07)</td>
                <td>Computer security</td>
                <td>07-01</td>
              </tr>
              <tr>
                <td>Security(07)</td>
                <td>Cryptology</td>
                <td>07-02</td>
              </tr>
              <tr>
                <td>Security(07)</td>
                <td>BlockChain</td>
                <td>07-03</td>
              </tr>
              <tr>
                <td>
                  Quantum <br />
                  computer & <br />
                  Information <br />
                  theory(08)
                </td>
                <td>Quantum computer</td>
                <td>08-01</td>
              </tr>
              <tr>
                <td>
                  Quantum <br />
                  computer & <br />
                  Information <br />
                  theory(08)
                </td>
                <td>Information theory</td>
                <td>08-02</td>
              </tr>
              <tr>
                <td>
                  Quantum <br />
                  computer & <br />
                  Information <br />
                  theory(08)
                </td>
                <td>Quantum information theory</td>
                <td>08-03</td>
              </tr>
              <tr>
                <td>
                  Algorithm <br />
                  theory(09)
                </td>
                <td>Algorithm theory</td>
                <td>09-01</td>
              </tr>
              <tr>
                <td>
                  Algorithm <br />
                  theory(09)
                </td>
                <td>Graph theory</td>
                <td>09-02</td>
              </tr>
              <tr>
                <td>
                  Algorithm <br />
                  theory(09)
                </td>
                <td>Computation theory</td>
                <td>09-03</td>
              </tr>
              <tr>
                <td>Math Education(10)</td>
                <td>Math Education</td>
                <td>10-01</td>
              </tr>
            </tbody>
          </table>
          <br />
          <br />

          <p className="content-p">
            4. 논문은 LaTeX을 이용하여 준비한다.
            {/*
            <a href="MITI.zip" download="MITI.zip" className="content_btn">
              양식 다운로드
            </a>
            */}
            <br />
            <br />
            5. 원고는 PDF 형식으로 제출한다.
            <br />
            <br />
            6. 기타 논문관련 문의사항은 수학기반 산업기술 혁신 연구회지
            논문이메일(info@miti.kr)로 보낸다.
            <br />
            <br />
            7. References는 첫 글자만 대문자로 쓰며 그 내용은 다음과 같이 쓴다.
            <br />
            ① 일련번호는 [1], [2], [3], …의 형태로 쓴다.
            <br />
            ② 저자의 이름 중 First name은 약자로 쓴다.
            <br />
            ③ 책, 논문 구분 없이 제목은 Italic체로 쓰고 잡지명, 출판사명은
            Roman체로 쓴다.
            <br />
            ④ 모든 서지사항, 특히 잡지명은 미국수학회의 데이터베이스인
            MathSciNet을 따른다.
            <br />
            ⑤ 논문의 경우, 저자명, 논문제목, 잡지명, volume (연도), issue, page
            순으로 하되 volume 번호는 bold체로 쓴다.
            <br />
            ⑥ 책의 경우, 출판사, 도시, 연도 순으로 쓴다.
            <br />
            <br />
            8. Style file을 다운로드 받을 수 있으므로 가능한 이 style file을
            이용하여 논문을 작성하면
            <br />
            보다 신속한 출판이 가능하다.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Submission_Infromation;
