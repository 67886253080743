import { sub_banner } from "../../src/assets/image/index";
import "../style/Sub_Banner.scss";

function Sub_Banner() {
  return (
    <div className="Sub_Banner_Box">
      <img src={sub_banner} alt="" />
      <div>
        <p>
          수학기반산업기술혁신학회 <br />
          <span>
            Mathematics-based Industrial Technology Innovation Academic Society
          </span>
        </p>
      </div>
    </div>
  );
}

export default Sub_Banner;
