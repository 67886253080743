import React from "react";
import Slider from "react-slick";
import { useNavigate, Link } from "react-router-dom";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../style/pages/main.scss";

import {
  banner_1,
  banner_2,
  banner_3,
  banner_btn,
  more,
  quickIcon_1,
  quickIcon_2,
  quickIcon_3,
  quickIcon_4,
  leftArrow,
  rightArrow,
} from "../assets/image/index";

import AcademicConferenceData from "../assets/object/academicConferenceData.js";
import noticeData from "../assets/object/noticeData.js";

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
      style={{ ...style, display: "block" }}
    >
      <img src={rightArrow} alt="next" />
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
      style={{ ...style, display: "block" }}
    >
      <img src={leftArrow} alt="prev" />
    </div>
  );
}

function Main() {
  let navigate = useNavigate();
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const societyNews = AcademicConferenceData.slice(0, 4).map((news) => ({
    id: news.id,
    title: news.title,
  }));

  const notices = noticeData.slice(0, 4).map((notice) => ({
    id: notice.id,
    title: notice.title,
  }));

  return (
    <div className="Main">
      <div className="Banner">
        <Slider {...settings}>
          <div>
            <img src={banner_1} alt="banner 1" />
          </div>
          <div>
            <img src={banner_2} alt="banner 2" />
          </div>
          <div>
            <img src={banner_3} alt="banner 3" />
          </div>
        </Slider>
        <div className="slider-border"> </div>
        <span className="Banner_Text">
          <p className="Title_Text">
            수학기반산업기술혁신학회에 오신것을 환영합니다.
          </p>
          <p>
            본 학회는 암호 스키마 및 알고리즘 연구에 대한 학술 및 기술의 진흥을
            위하여
            <br /> 전문가와 비전문가를 따지지 않고 관련 연구에 대한 학문적
            교류의 장을 목표로 창립되었습니다.
          </p>
        </span>
        <Link to="/journal/submission_information">
          <img src={banner_btn} className="Banner_Button" alt="" />
        </Link>
      </div>

      <div className="Artclass">
        <div className="Left_Art_Box">
          <div className="Art_Box_Title">학회소식</div>
          <p>
            {societyNews.map((news) => (
              <Link to={`/post/society_news/${news.id}`} key={news.id}>
                · <span>[새소식] </span>
                {news.title}
                <br />
              </Link>
            ))}
            <Link to="/notice/society_news">
              <img src={more} alt="more" />
            </Link>
          </p>
        </div>
        <div className="Right_Art_Box">
          <div className="Art_Box_Title">공지사항</div>
          <p>
            {notices.map((notice) => (
              <Link to={`/post/notice/${notice.id}`} key={notice.id}>
                · <span>[새소식] </span>
                {notice.title}
                <br />
              </Link>
            ))}
            <Link to="/notice/notice">
              <img src={more} alt="more" />
            </Link>
          </p>
        </div>
      </div>

      <div className="Quick_Menu">
        <div className="menu_bar">
          <Link to="/introduce/purpose">
            <div className="Menu no-after">
              <img src={quickIcon_1} alt="icon 1" />
              학회소개
            </div>
          </Link>
          <div className="line"></div>
          <Link to="/journal/submission_information">
            <div className="Menu">
              <img src={quickIcon_2} alt="icon 2" />
              투고안내
            </div>
          </Link>
          <div className="line"></div>

          <Link to="/">
            <div className="Menu">
              <img src={quickIcon_3} alt="icon 3" />
              논문
            </div>
          </Link>
          <div className="line"></div>

          <Link to="/article/research">
            <div className="Menu">
              <img src={quickIcon_4} alt="icon 4" />
              리서치
            </div>
          </Link>
        </div>
      </div>

      <div className="Serve_Banner">
        <div className="text">
          <span>다양한 분야의 지식이 결합되어, 새로운 지식을 창조합니다.</span>
        </div>
        <img src={banner_2} alt="serve banner" />
      </div>
    </div>
  );
}

export default Main;
