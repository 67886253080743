import React, { useState } from "react";
import "../style/Footer.scss";
import { log, company, phone, email, youtube } from "../assets/image/index";

function Footer() {
  if (window.location.pathname.match(/^\/showArticles(\/\d+)?$/)) return null;

  return (
    <div className="Footer">
      <div className="Upper_Box">
        <div className="Logo_Box">
          <img src={log} alt="" />
          <p>수학기반산업기술혁신학회</p>
        </div>
        <div className="Info_Box">
          <div>
            <img src={company} alt="" />
            <p>경기도 고양시 일산서구 킨텍스로 240 꿈에그린오피스동 7층 709호</p>
          </div>

          <div>
            <img src={phone} alt="" />
            <p>Tel : 031-995-6409</p>
          </div>

          <div>
            <img src={email} alt="" />
            <p>E-mail : info@miti.kr</p>
          </div>
        </div>
      </div>
      <div className="Under_Box">
        <img src={youtube} alt="" />
        <div>
          <p className="Clickable_Text">개인정보처리방침</p>
          <p>|</p>
          <p className="Clickable_Text">이메일무단수집거부</p>
        </div>
        <p>
          Copyright © 2022 Mathematics-based Industrial Technology Innovation
          Academic Society All right Reserved
        </p>
      </div>
    </div>
  );
}
export default Footer;
